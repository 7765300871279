import { NotFound } from "@de/ist.style_components_v3";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { baseAddress } from "@/config";
import PackageView from "@/views/PackageView.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "default",
		redirect: { name: "packages" },
	},
	{
		path: "/packages",
		name: "packages",
		component: PackageView,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/history",
		name: "history",
		component: async () => import("@/views/HistoryView.vue"),
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: NotFound,
	},
];

const router = createRouter({
	history: createWebHistory(baseAddress),
	routes,
});

export default router;
