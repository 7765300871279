<template>
	<div class="d-flex mb-2">
		<v-btn
			class="mr-2"
			color="primary"
			:disabled="canNotDeselect"
			prepend-icon="mdi-checkbox-blank-off-outline"
			variant="outlined"
			@click="deselectAll"
		>
			Alle abwählen
		</v-btn>
		<v-spacer></v-spacer>
		<v-btn
			color="primary"
			:disabled="canNotInstall"
			prepend-icon="mdi-package-variant-closed-check"
			@click="install"
		>
			Paket(e) bereitstellen
		</v-btn>
	</div>
</template>

<script setup lang="ts">
	import { computed } from "vue";

	export interface ActionElementProps {
		anyPackageSelected: boolean;
	}

	const props = withDefaults(defineProps<ActionElementProps>(), {
		anyPackageSelected: false,
	});

	const emit = defineEmits(["install", "deselectAll"]);

	const canNotInstall = computed(() => {
		if (!props.anyPackageSelected) {
			return true;
		}
		return false;
	});

	const canNotDeselect = computed(() => {
		if (!props.anyPackageSelected) {
			return true;
		}
		return false;
	});

	function install() {
		emit("install");
	}

	function deselectAll() {
		emit("deselectAll");
	}
</script>
