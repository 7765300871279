<template>
	<v-card class="pa-4">
		<div class="d-flex">
			<div class="pt-4">
				<v-checkbox v-model="itemSelected" color="primary"></v-checkbox>
			</div>
			<div class="big-avatar-item pr-4 pl-2">
				<img :src="iconUri" />
			</div>
			<div class="flex-column flex-fill">
				<p class="package-name">{{ name }}</p>
				<p v-if="version" class="package-version">Version: {{ version }}</p>
				<p class="package-publisher">Hersteller: {{ publisher }}</p>
			</div>
		</div>

		<v-card-text class="package-hints">
			<div class="line-break" :class="{ 'pb-4': !!hint }">
				<div v-if="description">
					<div
						:id="id + '-descr'"
						:class="{ 'preview-text': showDescrPreview }"
					>
						Beschreibung: {{ description }}
					</div>
					<v-btn
						v-if="showDescrButton"
						class="ml-n3"
						color="primary"
						size="small"
						variant="plain"
						@click="showDescrPreview = !showDescrPreview"
					>
						<span v-if="showDescrPreview"
							>mehr anzeigen<v-icon class="pl-2">mdi-chevron-down</v-icon></span
						><span v-if="!showDescrPreview"
							>weniger anzeigen<v-icon class="pl-2"
								>mdi-chevron-up</v-icon
							></span
						>
					</v-btn>
				</div>
				<div v-if="!description">Beschreibung: [unbekannt]</div>
			</div>
			<div v-if="hint" class="line-break">
				<div>
					<div :id="id + '-hint'" :class="{ 'preview-text': showHintPreview }">
						Hinweise: {{ hint }}
					</div>
					<v-btn
						v-if="showHintButton"
						class="ml-n3"
						color="primary"
						size="small"
						variant="plain"
						@click="showHintPreview = !showHintPreview"
					>
						<span v-if="showHintPreview"
							>mehr anzeigen<v-icon class="pl-2">mdi-chevron-down</v-icon></span
						><span v-if="!showHintPreview"
							>weniger anzeigen<v-icon class="pl-2"
								>mdi-chevron-up</v-icon
							></span
						>
					</v-btn>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script setup lang="ts">
	import { computed, onMounted, ref } from "vue";

	export interface PackageProps {
		id: string;
		packageId: string;
		name?: string;
		publisher?: string;
		version?: string;
		iconUri?: string;
		hint?: string;
		description?: string;
		packageType?: string;
		installerUri?: string;
		deinstallerUri?: string;
		selected?: boolean;
	}

	const props = withDefaults(defineProps<PackageProps>(), {
		deinstallerUri: "",
		description: "[unbekannt]",
		hint: "",
		iconUri: "favicon.ico",
		installerUri: "",
		name: "[unbekannt]",
		packageType: "",
		publisher: "[unbekannt]",
		selected: false,
		version: "",
	});

	const emit = defineEmits(["changeSelected"]);

	const itemSelected = computed({
		get() {
			return props.selected;
		},
		set(newValue: boolean) {
			emit("changeSelected", {
				id: props.id,
				selected: newValue,
			});
		},
	});

	const showDescrPreview = ref(true);
	const showDescrButton = ref(false);
	const showHintPreview = ref(true);
	const showHintButton = ref(false);

	function checkShowPrevButtons() {
		var descrElement = document.getElementById(props.id + "-descr");
		if (descrElement != null) {
			if (descrElement.offsetWidth < descrElement.scrollWidth) {
				showDescrButton.value = true;
			} else {
				showDescrButton.value = false;
			}
		}
		var hintElement = document.getElementById(props.id + "-hint");
		if (hintElement != null) {
			if (hintElement.offsetWidth < hintElement.scrollWidth) {
				showHintButton.value = true;
			} else {
				showHintButton.value = false;
			}
		}

		showDescrPreview.value = true;
		showHintPreview.value = true;
	}

	onMounted(() => {
		window.addEventListener("resize", checkShowPrevButtons);
		checkShowPrevButtons();
	});
</script>

<style lang="less" scoped>
	.avatar-item-centered {
		margin-top: 50px;
	}

	.big-avatar-item img {
		height: 92px;
		width: 92px;
	}

	.package-name {
		font-size: 24px;
		line-height: 1.6;
	}

	.package-version {
		color: rgba(0, 0, 0, 0.6);
		font-size: 14px;
		line-height: 2.6;
	}

	.package-publisher {
		color: rgba(0, 0, 0, 0.6);
		font-size: 14px;
		line-height: 1.6;
	}
	.package-hints {
		color: rgba(0, 0, 0, 0.6);
	}

	.line-break {
		white-space: pre-line;
	}

	.preview-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
