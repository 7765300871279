<template>
	<v-list class="pa-0">
		<organisation-list-items></organisation-list-items>
		<v-divide></v-divide>
		<v-list-item
			prepend-icon="mdi-logout"
			:title="$t('content-header.logout-btn')"
			@click="logout"
		></v-list-item>
	</v-list>
</template>

<script setup lang="ts">
	import { OrganisationListItems, useAuth } from "@de/ist-cloud-auth";

	const { logout } = useAuth();
</script>

<style scoped></style>
