import de from "./translations/de";
import en from "./translations/en";

const translations = {
	de,
	en,
} as const;

export default translations;

export type Language = keyof typeof translations;
