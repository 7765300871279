import { InjectionKey } from "vue";

export type SuccessDialogLabels = {
	title: string;
	text: string;
};

export const showSuccesDialogInjectionKey: InjectionKey<
	(labels: SuccessDialogLabels) => void
> = Symbol();
