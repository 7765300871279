import { messages } from "@de/ist.style_components_v3";
import { createI18n } from "vue-i18n";

import translations from "@/shared/translations";

const mergedTranslations = {
	...messages,
	...translations,
};

export default createI18n({
	fallbackLocale: "de",
	inheritLocale: true,
	legacy: false,
	locale: "de",
	messages: mergedTranslations as any,
} as const);
