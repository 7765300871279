<template>
	<div class="d-flex align-center mb-2">
		<v-text-field
			v-model="searchText"
			color="primary"
			density="comfortable"
			hide-details
			label="Suchbegriff"
			placeholder="Suchbegriff"
			variant="outlined"
			@keyup.enter="search"
		/>
		<v-btn
			class="ml-4"
			color="primary"
			density="comfortable"
			icon="mdi-magnify"
			title="Paket(e) suchen"
			variant="outlined"
			@click="search"
		/>
	</div>
</template>

<script setup lang="ts">
	import { ref } from "vue";

	const searchText = ref("");

	const emit = defineEmits(["changeSearch"]);

	function search() {
		emit("changeSearch", searchText.value);
	}
</script>
