import "@de/ist.style_components_v3/dist/ist.style_components_v3.css";

import {
	createStyleComponents,
	versionInjectionKey,
} from "@de/ist.style_components_v3";
import { createAuth } from "@de/ist-cloud-auth";
import { createPinia } from "pinia";
import { createApp } from "vue";

import ContentHeaderMenu from "@/views/layouts/ContentHeaderMenu.vue";

import App from "./App.vue";
// i18n
import i18n from "./plugins/i18n";
// Vuetify
import vuetify from "./plugins/vuetify";
// Router
import router from "./router";

const auth = createAuth({
	rightToUse: "IST.AppStore",
});
const version = process.env.VUE_APP_VERSION;
const styleComponents = createStyleComponents();

createApp(App)
	.use(i18n)
	.use(createPinia())
	.use(router)
	.use(vuetify)
	.use(auth)
	.use(styleComponents)
	.provide(versionInjectionKey, version)
	.component("ContentHeaderMenu", ContentHeaderMenu)
	.mount("#app");
