import { messages } from "@de/ist.style_components_v3";
import { de as vuetifyde } from "vuetify/locale";

import de from "./de.json";

export default {
	$vuetify: { ...vuetifyde },
	...messages.de,
	...de,
};
