<template>
	<div>
		<v-card class="pt-8 pb-4 pr-8 pl-8">
			<v-row>
				<v-text-field
					v-model="portalUrl"
					append-inner-icon="mdi-open-in-new"
					clearable
					color="primary"
					label="Portal-Adresse"
					placeholder="Relution-Portal Adresse"
					prepend-icon="mdi-web"
					variant="outlined"
					@click:append-inner="gotoPortal"
					@keyup.enter="testConnection"
				>
				</v-text-field>
			</v-row>
			<v-row>
				<v-text-field
					v-model="portalUser"
					clearable
					color="primary"
					label="Benutzer"
					placeholder="Relution-Portal Benutzer"
					prepend-icon="mdi-account"
					variant="outlined"
					@keyup.enter="testConnection"
				>
				</v-text-field>
			</v-row>
			<v-row>
				<v-text-field
					v-model="portalPassword"
					:append-inner-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
					clearable
					color="primary"
					label="Kennwort"
					placeholder="Relution-Portal Kennwort"
					prepend-icon="mdi-key"
					:type="showPass ? 'text' : 'password'"
					variant="outlined"
					@click:append-inner="showPass = !showPass"
					@keyup.enter="testConnection"
				>
				</v-text-field>
			</v-row>
			<v-row v-if="terms.length > 0" class="mb-4">
				<accept-terms-alert
					v-model="acceptTerms"
					:terms="terms"
				></accept-terms-alert>
			</v-row>
			<v-row>
				<v-spacer></v-spacer>
				<v-btn
					class="white--text"
					color="primary"
					:disabled="canNotTest"
					:loading="testingConnection"
					@click="testConnection"
				>
					<v-icon class="pr-2"> mdi-security-network </v-icon>Organisation(en)
					abrufen
				</v-btn>
			</v-row>
			<v-row class="pt-4">
				<v-select
					v-model="selectedClient"
					color="primary"
					:disabled="clients.length == 0"
					:item-title="(item) => item.name"
					:item-value="(item) => item"
					:items="clients"
					label="Organisation wählen"
					no-data-text="Keine Organisationen vorhanden"
					prepend-icon="mdi-domain"
					variant="outlined"
				></v-select>
			</v-row>
		</v-card>
	</div>
</template>

<script setup lang="ts">
	import { useAuth } from "@de/ist-cloud-auth";
	import { computed, inject, onMounted, ref, watch } from "vue";

	import { baseAddress } from "@/config";
	import { showErrorDialogInjectionKey } from "@/shared/errorDialogInjectionKey";

	import Organization from "../models/organizationEntry";
	import AcceptTermsAlert from "./AcceptTermsAlert.vue";

	const { organisationId, roleId } = useAuth();

	const fetchHeaders = {
		"Content-Type": "application/json",
		"X-Organisation-Id": organisationId.value ?? "",
		"X-Role-Id": roleId.value ?? "",
	};

	export interface RelutionProps {
		relutionUrl: string;
		relutionUser: string;
	}

	interface Term {
		type: string;
		version: string;
		link: string;
	}

	const props = withDefaults(defineProps<RelutionProps>(), {
		relutionUrl: "",
		relutionUser: "",
	});

	const emit = defineEmits(["changeClient"]);

	const showErrorDialog = inject(showErrorDialogInjectionKey, () => {});

	const portalUrl = ref("");
	const portalUser = ref("");
	const portalPassword = ref("");
	const clients = ref([] as Organization[]);
	const selectedClient = ref({} as Organization | undefined);
	const testingConnection = ref(false);
	const showPass = ref(false);
	const terms = ref<Term[]>([]);
	const acceptTerms = ref(false);

	const canNotTest = computed(() => {
		if (
			!portalUrl.value ||
			!portalUser.value ||
			!portalPassword.value ||
			(terms.value.length > 0 && !acceptTerms.value)
		) {
			return true;
		}
		return false;
	});

	watch(selectedClient, (newValue) => {
		emit(
			"changeClient",
			newValue,
			portalUrl.value,
			portalUser.value,
			portalPassword.value
		);
	});

	function testConnection() {
		if (!canNotTest.value) {
			testingConnection.value = true;
			setTimeout(() => {
				const requestOptions = {
					method: "POST",
					headers: fetchHeaders,
					body: JSON.stringify({
						Uri: portalUrl.value,
						Username: portalUser.value,
						Password: portalPassword.value,
						AcceptedTerms:
							terms.value.length > 0
								? terms.value.map((term) => {
										return {
											type: term.type,
											version: term.version,
										};
								  })
								: undefined,
					}),
				};
				fetch(baseAddress + "/v1/Relution/organizations", requestOptions)
					.then(async (response) => {
						if (!response.ok) {
							const error = await response.json();
							if (error.title === "USER_NEEDS_TO_ACCEPT_TERMS") {
								terms.value = JSON.parse(error.detail);
							} else {
								showErrorDialog({
									title: `Fehler ${error.status} (${error.title})`,
									text: `Fehler bei der Autorisierung: ${error.detail}`,
								});
							}
							testingConnection.value = false;
							return;
						}
						terms.value = [];
						clients.value = (await response.json()) as Organization[];
						if (clients.value.length) {
							selectedClient.value = clients.value[0];
						}
						testingConnection.value = false;
					})
					.catch((error) => {
						showErrorDialog(
							{
								title: "Fehler",
								text: "Fehler bei der Autorisierung:",
							},
							error
						);
						testingConnection.value = false;
					});
			});
		}
	}

	function gotoPortal() {
		if (
			portalUrl.value.indexOf(".") > 0 &&
			portalUrl.value.indexOf(".") < portalUrl.value.length - 2
		) {
			window.open(portalUrl.value, "_blank", "noreferrer");
		}
	}

	onMounted(() => {
		clients.value = [];
		selectedClient.value = undefined;
		portalUser.value = props.relutionUser;
		portalUrl.value = props.relutionUrl;
	});
</script>
