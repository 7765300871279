export type MenuItem = {
	readonly divider?: true;
	readonly title?: string;
	readonly path?: string;
	readonly icon?: string;
	readonly customIcon?: string;
};

export const menuItems: MenuItem[] = [
	{
		icon: "mdi-package-variant-closed",
		title: "Paketsuche",
		path: "packages",
	},
	{
		icon: "mdi-list-box-outline",
		title: "Historie",
		path: "history",
	},
];
