import { InjectionKey } from "vue";

export type ErrorDialogLabels = {
	title: string;
	text: string;
};

export const showErrorDialogInjectionKey: InjectionKey<
	(labels: ErrorDialogLabels, error?: Error) => void
> = Symbol();
