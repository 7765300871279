<template>
	<v-alert type="error" variant="tonal">
		Der Benutzer konnte nicht angemeldet werden, zuvor müssen erst folgende
		Bedingungen akzeptiert werden
		<v-checkbox-btn
			v-for="term in terms"
			:key="term.type"
			v-model="acceptedTerms"
			:value="term"
		>
			<template #label>
				<div>
					Ich habe die
					<a :href="term.link" target="_blank" @click.stop>
						{{ t(`terms.${term.type}`) }}
					</a>
					gelesen und akzeptiere diese.
				</div>
			</template>
		</v-checkbox-btn>
	</v-alert>
</template>

<script setup lang="ts">
	import { computed, ref, watch } from "vue";
	import { useI18n } from "vue-i18n";

	const { t } = useI18n();
	const props = defineProps<{
		terms: { type: string; link: string }[];
		modelValue: boolean;
	}>();
	const emit = defineEmits<{
		"update:modelValue": [value: boolean];
	}>();

	const acceptedTerms = ref([]);
	const allSelected = computed(
		() => acceptedTerms.value.length === props.terms.length
	);
	watch(allSelected, (newValue) => emit("update:modelValue", newValue));
</script>
