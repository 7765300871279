<template>
	<v-navigation-drawer color="primary" permanent :rail="rail">
		<template #prepend>
			<div class="drawer-header d-flex align-center pa-2">
				<div class="text-h6 text-truncate">IST.AppStore</div>
				<v-btn
					:block="rail"
					class="ml-auto"
					density="comfortable"
					:icon="rail ? 'mdi-menu' : 'mdi-menu-open'"
					:ripple="false"
					rounded="0"
					variant="text"
					@click.stop="rail = !rail"
				></v-btn>
			</div>
		</template>
		<v-list nav>
			<navigation-bar-item
				v-for="(menuItem, i) in visibleMenuItems"
				:key="i"
				:menu-item="menuItem"
			/>
		</v-list>
		<v-spacer />

		<template #append>
			<div
				class="drawer-footer d-flex flex-column justify-center align-center pa-2"
			>
				<v-img
					class="w-100"
					max-height="56"
					:src="require('@/assets/logo2.svg')"
				></v-img>
				<div class="d-flex flex-column justify-center align-center">
					<version-number />
				</div>
			</div>
		</template>
	</v-navigation-drawer>
</template>

<script setup lang="ts">
	import { VersionNumber } from "@de/ist.style_components_v3";
	import { useShouldDisplayNavigation } from "@de/ist-cloud-auth";
	import { computed, ref } from "vue";

	import { menuItems } from "@/components/navigation/menuItems";

	import NavigationBarItem from "./navigation/NavigationBarItem.vue";

	const displayNavigation = useShouldDisplayNavigation();

	const rail = ref(false);

	const visibleMenuItems = computed(() => {
		if (displayNavigation.value) {
			return menuItems;
		}
		return [];
	});
</script>

<style lang="less">
	.drawer-header {
		height: 62px;
	}

	.drawer-footer {
		gap: 8px;
	}
</style>
