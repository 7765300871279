<template>
	<v-app>
		<navigation-bar />
		<v-main>
			<v-container class="h-screen pa-0" fluid>
				<component :is="authView"></component>
			</v-container>
		</v-main>
		<common-dialog
			v-model="showCommonDialog"
			:text="commonDialogText"
			:title="commonDialogTitle"
			@confirm-btn-clicked="showCommonDialog = false"
		/>
		<error-dialog
			v-model="showErrorDialog"
			:error-object="errorDialogError"
			:text="errorDialogText"
			:title="errorDialogTitle"
			@confirm-btn-clicked="showErrorDialog = false"
		/>
	</v-app>
</template>

<script setup lang="ts">
	import {
		CommonDialog,
		contentHeaderPropsInjectionKey,
		ErrorDialog,
	} from "@de/ist.style_components_v3";
	import { useAuth } from "@de/ist-cloud-auth";
	import { useAuthView } from "@de/ist-cloud-auth";
	import { computed, provide, ref } from "vue";

	import { showErrorDialogInjectionKey } from "@/shared/errorDialogInjectionKey";

	import NavigationBar from "./components/NavigationBar.vue";
	import { showSuccesDialogInjectionKey } from "./shared/successDialogInjectionKey";

	const showCommonDialog = ref(false);
	const commonDialogTitle = ref<string>();
	const commonDialogText = ref<string>();

	provide(showSuccesDialogInjectionKey, ({ title, text }) => {
		commonDialogTitle.value = title;
		commonDialogText.value = text;

		showCommonDialog.value = true;
	});

	const showErrorDialog = ref(false);
	const errorDialogTitle = ref<string>();
	const errorDialogText = ref<string>();
	const errorDialogError = ref<Error>();

	provide(showErrorDialogInjectionKey, ({ title, text }, error) => {
		errorDialogTitle.value = title;
		errorDialogText.value = text;
		errorDialogError.value = error;

		showErrorDialog.value = true;
	});

	const authView = useAuthView();

	const { firstName, lastName, organisationName, roleName } = useAuth();

	const orgaTitle = computed(
		() => `${organisationName.value} - ${roleName.value}`
	);

	provide(contentHeaderPropsInjectionKey, () => ({
		firstName,
		lastName,
		orgaTitle,
	}));
</script>

<style>
	html,
	body,
	.v-application,
	.v-application--wrap,
	.v-application__wrap,
	.v-main,
	.v-main__wrap,
	.v-container,
	.height-100,
	#app {
		height: 100%;
	}

	.v-application {
		font-family: "Libre Franklin", sans-serif;
	}

	html {
		overflow-y: hidden !important;
	}
</style>
