<template>
	<page-layout title="packageview.title">
		<v-card-text>
			<loading-circle :loading="loadingData" text="Lade Daten">
				<search-bar @change-search="searchPackages" />
				<action-elements
					:any-package-selected="anyPackageSelected"
					@deselect-all="deselectAll"
					@install="installPackages"
				/>
				<v-divider class="my-4"></v-divider>
				<div class="mt-4">
					<loading-circle
						:loading="packages.length == 0 && loadingPackages"
						text="Suche Pakete"
					>
						<div
							v-if="
								packages.length == 0 &&
								!loadingPackages &&
								searchString.length > 0
							"
						>
							Es wurden keine Pakete für '{{ searchString }}' gefunden
						</div>
						<div
							v-if="
								packages.length == 0 &&
								!loadingPackages &&
								searchString.length == 0
							"
						>
							Bitte geben Sie einen Suchbegriff ein
						</div>
						<v-row v-for="packageItem in packages" :key="packageItem.id">
							<v-col cols="12">
								<package-item
									:id="packageItem.id"
									:deinstaller-uri="packageItem.deinstallerUri"
									:description="packageItem.description"
									:hint="packageItem.hint"
									:icon-uri="packageItem.iconUri"
									:installer-uri="packageItem.installerUri"
									:name="packageItem.name"
									:package-id="packageItem.packageId"
									:package-type="packageItem.packageType"
									:publisher="packageItem.publisher"
									:selected="packageItem.selected"
									:version="packageItem.version"
									@change-selected="setChangeSelected"
								/>
							</v-col>
						</v-row>
					</loading-circle>
				</div>
			</loading-circle>
			<v-dialog v-model="relutionDialog" width="800">
				<v-card class="">
					<v-card-title class="pl-6 pr-6 pt-6">
						<img class="pr-2" src="../../public/relution.png" />MDM-Portal
						Zugang
					</v-card-title>
					<v-card-text>
						<p class="pb-2">
							Bitte geben Sie die Zugangsdaten zu Ihrem MDM-Portal ein.
						</p>
						<p class="pb-4">
							Die Pakete werden für die ausgewählte Organisation bereitgestellt.
						</p>
						<relution-auth
							:relution-url="relutionUrl"
							:relution-user="relutionUser"
							@change-client="selectedClientChange"
						/>
					</v-card-text>
					<v-card-actions class="pa-6">
						<v-spacer></v-spacer>
						<v-btn
							class="pl-4 pr-4"
							color="primary"
							variant="outlined"
							@click="relutionDialog = false"
						>
							<v-icon class="pr-2"> mdi-package-variant-closed-remove </v-icon
							>Abbrechen
						</v-btn>
						<v-btn
							class="bg-primary white--text pl-4 pr-4"
							:disabled="canNotInstallConfirmed"
							:loading="installingPackages"
							@click="installConfirmed"
						>
							<v-icon class="pr-2"> mdi-package-variant-closed-check </v-icon
							>Paket(e) bereitstellen
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-text>
	</page-layout>
</template>

<script setup lang="ts">
	import { PageLayout } from "@de/ist.style_components_v3";
	import { useAuth } from "@de/ist-cloud-auth";
	import { computed, inject, onMounted, ref } from "vue";

	import LoadingCircle from "@/components/LoadingCircle.vue";
	import { baseAddress } from "@/config";
	import { showErrorDialogInjectionKey } from "@/shared/errorDialogInjectionKey";
	import { showSuccesDialogInjectionKey } from "@/shared/successDialogInjectionKey";

	import ActionElements from "../components/ActionElements.vue";
	import PackageItem from "../components/PackageItem.vue";
	import RelutionAuth from "../components/RelutionAuth.vue";
	import SearchBar from "../components/SearchBar.vue";
	import Organization from "../models/organizationEntry";
	import Package from "../models/packageEntity";
	import PackageErrorsEntity from "../models/packageErrorsEntity";
	import RelutionCredentials from "../models/relutionCredentials";

	const { organisationId, roleId } = useAuth();

	const fetchHeaders = {
		"Content-Type": "application/json",
		"X-Organisation-Id": organisationId.value ?? "",
		"X-Role-Id": roleId.value ?? "",
	};

	const showSuccessDialog = inject(showSuccesDialogInjectionKey, () => {});
	const showErrorDialog = inject(showErrorDialogInjectionKey, () => {});

	const loadingData = ref(false);
	const loadingPackages = ref(false);
	const searchString = ref("");
	const packages = ref([] as Package[]);
	const relutionDialog = ref(false);
	const selectedClient = ref({} as Organization);
	const relutionUrl = ref("");
	const relutionUser = ref("");
	const relutionPassword = ref("");
	const installingPackages = ref(false);

	const anyPackageSelected = computed(() => {
		if (
			packages.value.filter(
				(packageItem: Package) => packageItem.selected == true
			).length > 0
		) {
			return true;
		}
		return false;
	});

	const canNotInstallConfirmed = computed(() => {
		if (!selectedClient.value) {
			return true;
		}
		return false;
	});

	function setChangeSelected(selectedItemToChange: Package) {
		const foundElement = packages.value.find(
			(packageItem) => packageItem.id == selectedItemToChange.id
		);
		if (foundElement) {
			foundElement.selected = selectedItemToChange.selected;
		}
	}

	function searchPackages(inSearchString: string) {
		searchString.value = inSearchString;
		loadingPackages.value = true;
		packages.value = [];
		setTimeout(() => {
			fetch(baseAddress + "/v1/packages?search=" + inSearchString, {
				headers: fetchHeaders,
			})
				.then(async (response) => {
					if (!response.ok) {
						showErrorDialog({
							title: "Fehler " + response.status,
							text: "Fehler bei der Paketsuche: " + response.statusText,
						});
						loadingPackages.value = false;
						return;
					}
					const packagesResult = (await response.json()) as Package[];
					packagesResult.forEach((packageItem: Package) => {
						if (!packageItem.name) {
							packageItem.name = "[unbekannt]";
						}
						if (!packageItem.publisher) {
							packageItem.publisher = "[unbekannt]";
						}
						if (!packageItem.iconUri) {
							packageItem.iconUri = "favicon.ico";
						} else {
							packageItem.iconUri = baseAddress + packageItem.iconUri;
						}
					});
					packages.value = packagesResult;
					loadingPackages.value = false;
				})
				.catch((error) => {
					showErrorDialog(
						{
							title: "Fehler",
							text: "Fehler bei der Paketsuche:",
						},
						error
					);
					loadingPackages.value = false;
				});
		});
	}

	function selectedClientChange(
		inClient: Organization,
		inUrl: string,
		inUser: string,
		inPw: string
	) {
		selectedClient.value = inClient;
		relutionUrl.value = inUrl;
		relutionUser.value = inUser;
		relutionPassword.value = inPw;
	}

	function deselectAll() {
		packages.value
			.filter((packageItem: Package) => packageItem.selected == true)
			.forEach((packageItem: Package) => {
				packageItem.selected = false;
			});
	}

	function installPackages() {
		relutionDialog.value = true;
	}

	function installConfirmed() {
		installingPackages.value = true;
		setTimeout(() => {
			const selectedPackageIdList = [] as string[];

			packages.value
				.filter((packageItem: Package) => packageItem.selected == true)
				.forEach((packageItem: Package) => {
					selectedPackageIdList.push(packageItem.id);
				});

			const requestOptions = {
				method: "POST",
				headers: fetchHeaders,
				body: JSON.stringify({
					organization: {
						id: selectedClient.value.id,
						name: selectedClient.value.name,
					},
					packages: selectedPackageIdList,
					password: relutionPassword.value,
					uri: relutionUrl.value,
					username: relutionUser.value,
				}),
			};
			fetch(baseAddress + "/v1/Relution", requestOptions)
				.then(async (response) => {
					if (!response.ok) {
						installingPackages.value = false;
						relutionDialog.value = false;

						showErrorDialog({
							title: "Fehler " + response.status,
							text: "Fehler bei der Bereitstellung: " + response.statusText,
						});
						return;
					}

					const packagesResult = (await response.json()) as PackageErrorsEntity;
					installingPackages.value = false;

					if (packagesResult.errors.length) {
						const packageError = packages.value.find(
							(packageItem: Package) =>
								packageItem.id == packagesResult.errors[0].packageId
						) as Package;

						showErrorDialog({
							title: "Fehler " + packagesResult.errors[0].code,
							text:
								"Fehler bei der Bereitstellung der App '" +
								packageError.name +
								"': " +
								packagesResult.errors[0].description,
						});
						return;
					}

					relutionDialog.value = false;
					showSuccessDialog({
						title: "Paketbereitstellung",
						text:
							selectedPackageIdList.length == 1
								? "Ein Paket wurde erfolgreich bereitgestellt."
								: `${selectedPackageIdList.length} Pakete wurden erfolgreich bereitgestellt.`,
					});
				})
				.catch((error) => {
					installingPackages.value = false;
					relutionDialog.value = false;
					showErrorDialog(
						{
							title: "Fehler",
							text: "Fehler bei der Bereitstellung:",
						},
						error
					);
				});
		});
	}

	onMounted(() => {
		loadingData.value = true;
		setTimeout(() => {
			const requestOptions = {
				method: "GET",
				headers: fetchHeaders,
			};
			fetch(baseAddress + "/v1/Relution", requestOptions)
				.then(async (response) => {
					if (!response.ok) {
						showErrorDialog({
							title: "Fehler " + response.status,
							text:
								"Fehler beim Laden der Anmeldedaten: " + response.statusText,
						});
						loadingData.value = false;
						return;
					}

					const relutionCredResult =
						(await response.json()) as RelutionCredentials;
					relutionUrl.value = relutionCredResult.uri;
					relutionUser.value = relutionCredResult.username;
					loadingData.value = false;
				})
				.catch((error) => {
					showErrorDialog(
						{
							title: "Fehler",
							text: "Fehler beim Laden der Anmeldedaten:",
						},
						error
					);
					loadingData.value = false;
				});
		});
	});
</script>

<style lang="less">
	.view-elements-container {
		height: calc(100% - 82px);
		overflow-y: auto;
	}
</style>
